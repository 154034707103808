body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.suggestions {
  text-align: left;
  font-size: smaller;
  color: grey;
  border: 1px solid #ced4da;
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  overflow-y: auto;
  padding-left: 0;
  width: 100%;
  position: absolute;
  top: 40px;
  background-color: rgba(255, 255, 255, 1);
  z-index: 10;
  border-radius: 0.25rem;
  -moz-box-shadow: 0 0px 15px 3px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 0px 15px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0px 15px 3px rgba(0, 0, 0, 0.2);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestion-active,
.suggestions li:hover {
  background-color: #c5d4e2;
  color: black;
  cursor: pointer;
}